import { $fetch, $post, $put } from '@/services/axios';

export function fetch(params) {
  return $fetch(`operator/operator/configs`, params);
}

export function validate(model) {
  return $post(`operator/operator/config/validate`, model);
}

export function create(model) {
  return $put(`operator/operator/config`, model);
}

export function edit(model) {
  return $post(`operator/operator/config/${model.operatorId}/${model.id}`, model);
}
