<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :width="500">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" :rules="rules" class="n-modal-container" @submit.native.prevent>
      <n-row-col1>
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-selection v-model="model.operatorId" field-name="operatorId" selection-name="admin.operator" label="operator" :error="error.operatorId" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-enum v-model="model.configType" field-name="configType" enum-name="AdminConfigType" label="configType" :error="error.configType" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-text v-model="model.configValue" field-name="configValue" enum-name="LoginRestriction" label="configValue" :error="error.configValue" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { shouldNotEmpty, shouldBeJson } from '@/helpers/validate';
import { create, edit, validate } from '@/api/operator/operator-operator-config';

export default NModal.extend({
  name: 'ModalOperatorConfig',

  components: { NModal },

  data() {
    return {
      rules: {
        configValue: [shouldNotEmpty, shouldBeJson],
      },
    };
  },

  methods: {
    doValidate(model) {
      return validate(model);
    },

    doSubmit(model) {
      return this.isCreate ? create(model) : edit(model);
    },
  },
});
</script>
